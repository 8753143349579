<template>
  <v-card
    class="product-slider-card"
    outlined
    :to="link"
    @click="$emit('selectProduct')"
  >
    <v-img
      :src="img"
      alt="Immagine categoria"
      class="product-slider-card-image"
      cover
    />
    <v-card-text v-if="text" v-html="text"> </v-card-text>
    <v-spacer></v-spacer>
    <v-card-actions v-if="link && linkLabel">
      <v-btn :to="link" color="secondary" block depressed
        >{{ linkLabel }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>
<style lang="scss">
.product-slider-card {
  display: flex;
  flex-direction: column;
  height: 387px;
  max-height: 387px;
  border: 1px solid var(--v-grey-lighten1);
  border-radius: 10px !important;
  overflow: hidden;
  .product-slider-card-image {
    height: 387px;
  }
  @media #{map-get($display-breakpoints, 'sm-and-down')} {
    height: 235px;
    .product-slider-card-image {
      height: 235px;
    }
  }
}
</style>
<script>
export default {
  name: "ProductSliderCard",
  props: {
    img: { type: String, required: true },
    text: { type: String, required: true },
    link: { type: [String, Object], required: false },
    linkLabel: { type: String, required: false }
  }
};
</script>
